<template>
  <div class="paynow-cancel-confirm-modal">
    <div>
      <ion-label class="fw-600 fs-3" color="danger">{{ $t('orderB2b.cancelPaymentTitle') }}</ion-label>
    </div>
    <div class="my-3 text-center">
      <ion-label>{{ $t('orderB2b.cancelPaymentHint') }}</ion-label>
    </div>
    <ion-row>
      <ion-col col-6>
        <ion-button fill="outline" size="small" @click="$emit('on-no')" color="primary">{{
          $t('orderB2b.cancelStayButton')
        }}</ion-button>
      </ion-col>
      <ion-col col-6>
        <ion-button size="small" @click="$emit('on-yes')" color="danger">{{
          $t('orderB2b.cancelConfirmButton')
        }}</ion-button>
      </ion-col>
    </ion-row>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['on-no', 'on-yes']
});
</script>
<style lang="scss" scoped>
.paynow-cancel-confirm-modal {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
</style>
